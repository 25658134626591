
<template>
  <!-- <v-card
    if="expenses-stats"
    class="mx-auto mt-0"
    elevation="6"
    id="table"
    color="grey darken-4"
  >
    <v-card-text :style="`height:244px; overflow-y: auto;`">-->

  <v-row
    justify="start"
    :style="`height:220px; overflow-y: auto;`"
    class="my-0 py-0"
  >
    <v-col cols="6" md="4" style="padding-left: 5%" class="d-flex my-0 py-0">
      <v-select
        :style="$vuetify.breakpoint.xsOnly ? 'margin-top: 5px' : ''"
        dense
        :items="tiposDeGraficos"
        v-model="tipo"
        @change="fetchExpenses"
        outlined
        class="expsenseSelect"
      ></v-select>
    </v-col>
    <zingchart :data="myData" :series="items" style="height: 150px"></zingchart>
  </v-row>

  <!--</v-card-text>
  </v-card>-->
</template>
<script>
import { mapActions } from "vuex";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
export default {
  name: "ExpensesStats",

  watch: {
    options: {
      handler() {
        this.fetchExpenses();
      },
      deep: true,
    },
  },
  components: {
    zingchart: zingchartVue,
  },
  data() {
    return {
      items: [],
      totalExpenses: 0,
      options: {},
      from: null,
      to: null,
      tipo: "ingresos",
      tiposDeGraficos: [
        { text: this.$t("ingresos"), value: "ingresos" },
        { text: this.$t("gastos"), value: "gastos" },
        { text: this.$t("all"), value: "all" },
        //{ text: this.$t("forecast"), value: "forecast" },
      ],

      myData: {
        type: "line",
        backgroundColor: "none",
        "scale-x": {
          labels: [],
        },
        legend: {},
        "scale-y": {
          guide: {
            "line-color": "var(--v-gris1-base)",
          },
        },
        plotarea: {
          adjustLayout: true,
          marginRight: "2%",
          margiTop: "2%",
          height: "110px",
        },
        plot: {
          "bar-max-width": "12.5%",
          "border-radius-bottom-right": 10,
          "border-radius-top-right": 10,
        },
      },
    };
  },
  mounted() {
    let date = new Date();
    this.from = new Date(date.getFullYear(), date.getMonth(), 1);
    this.to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fetchExpenses();
  },
  methods: {
    ...mapActions("expenses", ["getExpensesSumByDay"]),
    fetchExpenses() {
      this.items = [];
      console.log("fetch", this.type);
      this.getExpensesSumByDay({
        paginate: this.options,
        filters: { ...this.filters(this.tipo + "-forecast"), no_order: true },
      }).then((expenses) => {
        this.procesExpense(expenses, "gris1", this.tipo + "-forecast");
      });
      this.getExpensesSumByDay({
        paginate: this.options,
        filters: { ...this.filters(this.tipo), no_order: true },
      }).then((expenses) => {
        this.procesExpense(expenses, "primary", this.tipo);
      });
    },
    procesExpense(expenses, color, tipo) {
      let items = [];
      let value = 0;
      //console.log("day", this.to.getDate());
      for (let i = 1; i <= this.to.getDate(); i++) {
        let val = expenses.find((x) => new Date(x.data).getDate() === i);
        if (val) {
          value += val.sum;
        }
        if (tipo !== "all" && tipo !== "all-forecast")
          items.push(Math.abs(value));
        else items.push(value);
      }
      this.items.push({
        values: items,
        "line-color": `var(--v-${color}-base)`,
        text: this.$t(tipo),
        marker: {
          "background-color": `var(--v-${color}-base)` /* hexadecimal or RGB value */,
          size: 5 /* in pixels */,
          "border-width": 0 /* in pixels */,
        },
      });
    },
    filters(type) {
      switch (type) {
        case "gastos":
          return { gasto: true, pending: false, from: this.from, to: this.to };
        case "ingresos":
          return { gasto: false, pending: false, from: this.from, to: this.to };
        case "all":
          return { pending: false, from: this.from, to: this.to };
        case "gastos-forecast":
          return { gasto: true, from: this.from, to: this.to };
        case "ingresos-forecast":
          return { gasto: false, from: this.from, to: this.to };
        case "all-forecast":
          return { from: this.from, to: this.to };
        default:
          return {};
      }
    },
  },
};
</script>
<style lang="sass" >
.expsenseSelect
  .v-text-field.v-input--dense:not(.v-textarea) fieldset
    height: 32px
    margin-top: 9px
  .v-input__icon
    margin-top: 3px
</style>